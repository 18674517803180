<template>
  <div class="">
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <form ref="form" method="post" @submit.prevent="handleSubmit()">
        <div class="col-sm-12 mb-3">
          <ValidationProvider rules="required" v-slot="{ errors }">
            <c-yes-or-no-input
              question="Cette offre est-elle liée à un programme particulier de ANPE?"
              v-model="isForProgramme"
            >
              <template #question="{ question }">
                <div class="mb-1">
                  <label>{{ question }}</label>
                </div>
              </template>
            </c-yes-or-no-input>
            <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
              err
            }}</span>
          </ValidationProvider>
        </div>
        <div class="col-sm-12 mb-3" v-if="isForProgramme || editableItem.programme">
          <ValidationProvider rules="required" v-slot="{ errors }">
            <label for="programme">Programme</label>
            <div class="form-floating mb-3">
              <AsyncSearchInput
                v-model="editableItem.programme"
                :multiple="false"
                :queryUrl="'programmes'"
                :optionLabel="'nom'"
                :searchOptions="['nom']"
                placeholder="Rechercher un programme"
              />
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </div>
          </ValidationProvider>
        </div>
        <div class="col-sm-12" v-if="!isEntreprise">
          <label>Entreprise</label>
          <ValidationProvider rules="required" v-slot="{ errors }">
            <div class="form-floating mb-3">
              <entreprise-select
                v-model="editableItem.employeur"
                :options="entreprises"
                :close-on-select="true"
                :clear-on-select="false"
                :preserve-search="true"
                placeholder="Entreprise"
                label="raisonSocial"
                track-by="raisonSocial"
                :preselect-first="false"
              />
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </div>
          </ValidationProvider>
        </div>

        <div class="col-sm-12">
          <label class="typo__label">Intitule</label>
          <ValidationProvider rules="required" v-slot="{ errors }">
            <div class="form-floating mb-3">
              <input
                :value="editableItem.intitule"
                @input="handleInput"
                type="text"
                class="form-control"
                id="intitule"
                name="intitule"
                placeholder="Intitule"
              />
              <label for="intitule">Intitule</label>
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </div>
          </ValidationProvider>
        </div>
        <div class="col-sm-12" v-if="!isEntreprise">
          <!-- <label class="typeOffre">Type d'offre</label> -->
          <ValidationProvider rules="required" v-slot="{ errors }">
            <div class="form-floating mb-3">
              <!-- <multiselect
                v-model="editableItem.typeOffre"
                :options="typeOffres"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                placeholder="Offre d'offre"
                label="label"
                track-by="typeOffre"
                :preselect-first="false"
              /> -->

              <c-select
                id="typeOffre"
                name="typeOffre"
                option-label="label"
                option-value="code"
                :emit-object="false"
                v-model="editableItem.typeOffre"
                :options="typeOffres"
                class="form-select"
              >
              </c-select>

              <label class="typeOffre">Type d'offre </label>

              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </div>
          </ValidationProvider>
        </div>

        <div class="row">
          <div class="col-sm-6">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <input
                  v-uppercase
                  :value="editableItem.annExp"
                  @input="handleInput"
                  type="number"
                  class="form-control"
                  id="annExp"
                  name="annExp"
                  placeholder="Années d'experience"
                />
                <label for="annExp">Durrée d'experience</label>
                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                  err
                }}</span>
              </div>
            </ValidationProvider>
          </div>
          <div class="col-sm-6">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <!-- <multiselect
                  v-model="editableItem.uniteExperience"
                  :options="uniteExperiences"
                  :multiple="false"
                  :close-on-select="true"
                  :clear-on-select="false"
                  :preserve-search="true"
                  placeholder="Unité expérence"
                  label="libelle"
                  track-by="libelle"
                  :preselect-first="false"
                /> -->
                <c-select
                  id="uniteExperience"
                  name="uniteExperience"
                  option-label="label"
                  option-value="code"
                  :emit-object="false"
                  v-model="editableItem.uniteExperience"
                  :options="uniteExperiences"
                  class="form-select"
                >
                </c-select>
                <label for="uniteExperience">Unité expérence</label>
                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                  err
                }}</span>
              </div>
            </ValidationProvider>
          </div>

          <div class="col-sm-12">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <input
                  :value="editableItem.nombre"
                  @input="handleInput"
                  type="number"
                  class="form-control"
                  id="nombre"
                  name="nombre"
                  placeholder="Nombre de poste"
                />
                <label for="nombre">Nombre de poste</label>
                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                  err
                }}</span>
              </div>
            </ValidationProvider>
          </div>
        </div>
        <div class="col-sm-12">
          <label class="typo__label">Métier</label>
          <ValidationProvider rules="required" v-slot="{ errors }">
            <div class="form-floating mb-3">
              <!-- <multiselect
                v-model="editableItem.poste"
                :options="romes"
                :multiple="false"
                :close-on-select="true"
                :clear-on-select="false"
                  :preserve-search="true"
                placeholder="Poste"
                label="libelle"
                track-by="libelle"
                :preselect-first="false"
              /> -->
              <!-- <MetierSelect v-model="editableItem.poste">
              </MetierSelect> -->
              <metier-select v-model="editableItem.poste" :options="romes">
              </metier-select>

              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </div>
          </ValidationProvider>
        </div>
        <div class="col-sm-12">
          <label class="typo__label">Domaine(s) d'étude(s)</label>
          <ValidationProvider rules="required" v-slot="{ errors }">
            <div class="form-floating mb-3">
              <label class="typo__label">Demaine d'etudes</label>
              <AsyncSearchInput
                v-model="editableItem.domaines"
                :multiple="true"
                :queryUrl="'domaines'"
                :optionLabel="'nom'"
                :searchOptions="['nom']"
                placeholder="Rechercher un domaine d'études"
              />
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
              <!-- <multiselect
                v-model="editableItem.domaines"
                :options="domaines"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                placeholder="Domaine d'études"
                label="nom"
                track-by="nom"
                :preselect-first="false"
              />

              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span> -->
            </div>
          </ValidationProvider>
        </div>
        <!--<div class="col-sm-12">
          <ValidationProvider rules="required" v-slot="{ errors }">
            <div class="form-floating mb-3">
              <label class="typo__label">Poste</label>
              <multiselect
                v-model="editableItem.poste"
                :options="romes"
                :multiple="false"
                :close-on-select="true"
                :clear-on-select="false"
                :preserve-search="true"
                placeholder="poste"
                label="nom"
                track-by="nom"
                :preselect-first="false"
              />

              <span
                v-for="(err, index) in errors"
                :key="index"
                class="text-danger"
                >{{ err }}</span
              >
            </div>
          </ValidationProvider>
        </div>-->
        <div class="col-sm-12">
          <ValidationProvider rules="required" v-slot="{ errors }">
            <label for="descriptionTache">Description de tache</label>
            <div class="form-floating mb-3">
              <vue-editor
                id="descriptionTache"
                v-model="editableItem.descriptionTache"
              ></vue-editor>

              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </div>
          </ValidationProvider>
        </div>
        <div class="col-sm-12">
          <label for="descriptionTache">Nature</label>
          <ValidationProvider rules="required" v-slot="{ errors }">
            <div class="form-floating mb-3">
              <label class="typo__label">Nature</label>
              <multiselect
                v-model="editableItem.nature"
                :options="natures"
                :close-on-select="true"
                :clear-on-select="false"
                :preserve-search="true"
                placeholder="Natures"
                label="description"
                track-by="description"
                :preselect-first="false"
              />
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </div>
          </ValidationProvider>
        </div>
        <div class="col-sm-12">
          <label for="descriptionTache">Niveau</label>
          <ValidationProvider rules="required" v-slot="{ errors }">
            <div class="form-floating mb-3">
              <label class="typo__label">Niveau d'etudes</label>
              <multiselect
                v-model="editableItem.niveaus"
                :options="niveaux"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                placeholder="Niveau d'études"
                label="description"
                track-by="description"
                :preselect-first="false"
              />
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </div>
          </ValidationProvider>
        </div>
        <div class="col-sm-12">
          <label for="descriptionTache">Pièces à fournir</label>
          <ValidationProvider rules="required" v-slot="{ errors }">
            <div class="form-floating mb-3">
              <multiselect
                v-model="editableItem.typePieces"
                :options="pieces"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                placeholder="Type de pieces"
                label="libelle"
                track-by="libelle"
                :preselect-first="false"
              />
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </div>
          </ValidationProvider>
        </div>
        <div class="col-sm-12">
          <label for="descriptionTache">Langue</label>
          <ValidationProvider rules="required" v-slot="{ errors }">
            <div class="form-floating mb-3">
              <multiselect
                v-model="editableItem.langues"
                :options="ethnies"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                placeholder="Langues"
                label="nom"
                track-by="nom"
                :preselect-first="false"
              />
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </div>
          </ValidationProvider>
        </div>
        <div class="col-sm-12">
          <ValidationProvider rules="required" v-slot="{ errors }">
            <div class="form-floating mb-3">
              <!-- <multiselect
                v-model="editableItem.heureHebo"
                :options="heureHebdomadaires"
                :multiple="false"
                :close-on-select="true"
                :clear-on-select="false"
                :preserve-search="true"
                return="code"
                placeholder="Heure hebdomadaire"
                label="label"
                track-by="code"
                :preselect-first="false"
              /> -->
              <c-select
                id="dureeHebdomadaire"
                name="dureeHebdomadaire"
                option-label="label"
                option-value="code"
                :emit-object="false"
                v-model="editableItem.dureeHebdomadaire"
                :options="heureHebdomadaires"
                class="form-select"
              >
              </c-select>
              <label for="dureeHebdomadaire">Emploi de temps</label>

              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </div>
          </ValidationProvider>
        </div>
        <div class="col-sm-12">
          <ValidationProvider rules="required" v-slot="{ errors }">
            <div class="form-floating mb-3">
              <!-- <multiselect
                v-model="editableItem.niveauService"
                :options="niveauServices"
                :multiple="false"
                :close-on-select="true"
                :clear-on-select="false"
                :preserve-search="true"
                placeholder="Niveau de service"
                label="label"
                track-by="label"
                :preselect-first="false"
              /> -->
              <c-select
                id="niveauService"
                name="niveauService"
                option-label="label"
                option-value="code"
                :emit-object="false"
                v-model="editableItem.niveauService"
                :options="niveauServices"
                class="form-select"
              >
              </c-select>
              <label for="niveauService">Niveau de service</label>
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </div>
          </ValidationProvider>
        </div>

        <!-- <div class="col-sm-12">
          <ValidationProvider rules="required" v-slot="{ errors }">
            <div class="form-floating mb-3">
              <input
                :value="editableItem.lieuDeDepot"
                @input="handleInput"
                v-uppercase
                type="text"
                class="form-control"
                id="lieuDeDepot"
                name="lieuDeDepot"
                placeholder="lieuDeDepot"
              />
              <label for="lieuDeDepot">Lieu De Depot</label>
              <span
                v-for="(err, index) in errors"
                :key="index"
                class="text-danger"
                >{{ err }}</span
              >
            </div>
          </ValidationProvider>
        </div> -->
        <div class="row">
          <div class="col-sm-6">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <input
                  v-uppercase
                  :value="editableItem.ageMin"
                  @input="handleInput"
                  type="number"
                  class="form-control"
                  id="ageMin"
                  name="ageMin"
                  placeholder="Age Minimun"
                />
                <label for="ageMin">Age Minimun</label>
                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                  err
                }}</span>
              </div>
            </ValidationProvider>
          </div>
          <div class="col-sm-6">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <input
                  v-uppercase
                  :value="editableItem.ageMax"
                  @input="handleInput"
                  type="number"
                  class="form-control"
                  id="ageMax"
                  name="ageMax"
                  placeholder="Age Maximum"
                />
                <label for="ageMax">Age Maximum</label>
                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                  err
                }}</span>
              </div>
            </ValidationProvider>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <label>Date de début de l'opération</label>
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <p-calendar
                  class="col-12 md:col-12"
                  :showIcon="true"
                  size="sm"
                  locale="fr"
                  name="dateEff"
                  :manualInput="false"
                  v-model="editableItem.dateEff"
                  :showWeek="true"
                />

                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                  err
                }}</span>
              </div>
            </ValidationProvider>
          </div>
          <div class="col-sm-6">
            <label>Date clôture dépôt de dossier</label>
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <p-calendar
                  class="col-12 md:col-12"
                  :showIcon="true"
                  size="sm"
                  locale="fr"
                  name="dateEch"
                  :manualInput="false"
                  v-model="editableItem.dateEch"
                  :showWeek="true"
                />

                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                  err
                }}</span>
              </div>
            </ValidationProvider>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <label>Date debut publication</label>
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <p-calendar
                  class="col-12 md:col-12"
                  :showIcon="true"
                  size="sm"
                  locale="fr"
                  name="dateDebutPub"
                  :manualInput="false"
                  v-model="editableItem.dateDebutPub"
                  :showWeek="true"
                />
                <!-- <input
                  :value="editableItem.dateDebutPub"
                  @input="handleInput"
                  type="date"
                  class="form-control"
                  id="dateDebutPub"
                  name="dateDebutPub"
                  placeholder="Date debut publication<"
                /> -->

                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                  err
                }}</span>
              </div>
            </ValidationProvider>
          </div>
          <div class="col-sm-6">
            <label>Date fin publication</label>
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <p-calendar
                  class="col-12 md:col-12"
                  :showIcon="true"
                  size="sm"
                  locale="fr"
                  name="dateFinPub"
                  :manualInput="false"
                  v-model="editableItem.dateFinPub"
                  :showWeek="true"
                />
                <!-- <input
                  :value="editableItem.dateFinPub"
                  @input="handleInput"
                  type="date"
                  class="form-control"
                  id="dateFinPub"
                  name="dateFinPub"
                  placeholder="Date fin publication"
                /> -->

                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                  err
                }}</span>
              </div>
            </ValidationProvider>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { VueEditor } from "vue2-editor";
import Multiselect from "vue-multiselect";
import { authMixin } from "../../../../../mixins/auth-mixin";
import MetierSelect from "../../../../common/MetierSelect.vue";
import CEntrepriseSelect from "../../../../common/CEntrepriseSelect.vue";
import AsyncSearchInput from "@/components/common/AsyncSearchInput.vue";
import CYesOrNoInput from "@/components/CYesOrNoInput.vue";

export default {
  props: ["value"],
  components: {
    VueEditor,
    Multiselect,
    MetierSelect,
    CYesOrNoInput,
    AsyncSearchInput,
    EntrepriseSelect: CEntrepriseSelect,
  },
  mixins: [authMixin],
  data() {
    return {
      editableItem: { ...this.value },
      isForProgramme: this.value != null ? this.value.programme : null,
    };
  },
  computed: {
    ...mapGetters({
      domaines: "domaine/domaines",
      natures: "nature/natures",
      niveaux: "niveau/niveaux",
      typeOffres: "setting/typeOffres",
      niveauServices: "setting/niveauServices",
      uniteExperiences: "setting/uniteExperiences",
      heureHebdomadaires: "setting/heureHebdomadaires",
      pieces: "piece/typePieces",
      romes: "rome/romes",
      ethnies: "ethnie/ethnies",
      entreprises: "entreprise/entreprises",

      // formeJuridique: "setting/formeJuridique",
    }),
    offreId() {
      return this.$route.params.offreId;
    },
    isEntreprise() {
      return this.$userRole == "ROLE_ENTREPRISE";
    },
    maxDate() {
      const now = new Date();
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      today.setFullYear(now.getFullYear() - 15);
      return today;
    },
  },
  created() {
    // this.fetchDomaines();
    this.fetctNatures();
    this.fetchNiveaux();
    this.fetchTypePieces();
    this.fetchRomes();
    this.fetchEthnies();
    if (!this.isEntreprise) {
      this.fetchEntreprises2();
    }

    if (this.offreId != null) {
      this.getOneOffre(this.offreId).then((data) => {
        this.editableItem = {
          ...this.editableItem,
          ...data,
        };
      });
    }
  },
  watch: {
    editableItem: {
      handler() {
        console.log(this.editableItem);
        this.$emit("input", this.editableItem);
      },
      deep: true,
    },
    "editableItem.hasNip": {
      handler() {
        if (this.editableItem.hasNip === false) {
          this.editableItem.npi = null;
        }
      },
      isForProgramme(val) {
        if (!val) this.this.editableItem.programme = null;
      },
    },
  },
  methods: {
    ...mapActions({
      fetchDomaines: "domaine/fetchDomaines",
      fetchNiveaux: "niveau/fetchNiveaux",
      fetctNatures: "nature/fetchNatures",
      fetchTypePieces: "piece/fetchTypePieces",
      fetchRomes: "rome/fetchRomes",
      fetchEthnies: "ethnie/fetchEthnies",
      getOneOffre: "offre/getOneOffre",
      fetchEntreprises2: "entreprise/fetchEntreprises2",
    }),
    handleInput(e) {
      this.editableItem[e.target.name] = e.target.value;
      this.$emit("input", this.editableItem);
    },
    async isCompleted() {
      const valid = await this.$refs.observer.validate();
      return valid;
    },
  },
};
</script>

<style scoped></style>
-->
